export enum CookieNames {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  tokenExpiresAt = 'tokenExpiresAt',
  orgId = 'orgId',
}

export enum Permission {
  ManageUsers = 'manage_users',
  ManageRoles = 'manage_roles',
  CreateVideos = 'create_videos',
  DeleteVideos = 'delete_videos',
  CreateFolders = 'create_folders',
  DeleteFolders = 'delete_folders',
  ManageVideoTags = 'manage_video_tags',
  ManageTrainings = 'manage_trainings',
  ManageMatches = 'manage_matches',
}

export type SessionTokenType = {
  accessToken?: string;
  expiresAt?: any;
  refreshToken?: string;
};

export type UserType = {
  user: {
    id: string;
    email: string;
    name: string;
    isSuperadmin?: boolean;
  };
  orgUsers: OrgUserType[];
  currentOrgId: string;
  currentPermissions: Permission[];
};

export type OrgUserType = {
  organization: {
    id: string;
    name: string;
  };
  orgRole: OrgRoleType;
};

export type OrgRoleType = {
  id: string;
  name: string;
  orgRolePermissions?: {
    action: Permission;
  }[];
};

export type AnotherOrgUserType = {
  id: string;
  user: {
    id: string;
    email: string;
    name: string;
    isSuperadmin?: boolean;
  };
  orgRole: OrgRoleType;
};

export type OrgGroupType = {
  id: string;
  name: string;
};

export type UserInviteType = {
  id: string;
  email: string;
  invitedUser: {
    id: string;
  };
  createdAt: string;
  createdBy: {
    name: string;
    email: string;
    isSuperadmin?: boolean;
  };
};

export type UserVideoClipType = {
  id: string;
  video: {
    name: string;
    transcodedAttachmentUrl: string;
    transcodedByteSize: string;
  };
};

export type SignInInput = {
  email: string;
  password: string;
};

export type SignInWithTelegramInput = {
  authDate: number;
  clientMutationId?: string;
  firstName?: string;
  hash: string;
  id: string;
  lastName?: string;
  photoUrl?: string;
  scope?: string;
  username?: string;
};

export type UserInviteCreateInput = {
  orgId: string;
  url: string;
  users: {
    email: string;
    orgRoleId: string;
  }[];
};

export type OrgUserUpdateRoleInput = {
  orgUserId: string;
  orgRoleId: string;
};

export type UserRenameInput = {
  userId: string;
  name: string;
};

export type OrgUserDeleteInput = {
  id: string;
};

export type UserGroupCreateInput = {
  orgId: string;
  name: string;
};

export type UserGroupUpdateInput = {
  id: string;
  name: string;
};

export type UserGroupAddUserInput = {
  userId: string;
  userGroupId: string;
};

export type UserGroupDeleteUserInput = {
  userId: string;
  userGroupId: string;
};
