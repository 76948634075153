import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './theme/globals.css';

import App from './app';
import ErrorBoundary from './providers/ErrorBoundary';
import client from 'services/client';
import TrackToken from 'utils/trackToken';

const root = createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ErrorBoundary>
        <TrackToken />
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </ApolloProvider>,
);
