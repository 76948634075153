import { gql } from '@apollo/client';

import { client } from 'services/client';
import {
  OrgUserUpdateRoleInput,
  SignInInput,
  SignInWithTelegramInput,
  UserGroupAddUserInput,
  UserGroupCreateInput,
  UserGroupDeleteUserInput,
  UserGroupUpdateInput,
  UserInviteCreateInput,
  UserRenameInput,
} from 'types/user';

// FRAGMENTS //////////////////////////////////////////////////////////////////

const ANOTHER_USER_IN_ORG_FRAGMENT = gql`
  fragment AnotherUserInOrg on OrgUser {
    id
    user {
      id
      name
      email
      isSuperadmin
    }
    orgRole {
      id
      name
    }
  }
`;

// QUERIES ////////////////////////////////////////////////////////////////////

const QUERY_USER_INFO = gql`
  query UserInfo($orgId: ID) {
    currentIdentity {
      ... on Identity {
        orgUsers(orgId: $orgId) {
          organization {
            id
            name
          }
          orgRole {
            orgRolePermissions {
              action
            }
          }
        }
        user {
          id
          email
          name
          isSuperadmin
        }
      }
    }
  }
`;

const QUERY_AVAILABLE_ORGS = gql`
  query AvailableOrgs {
    currentIdentity {
      ... on Identity {
        orgUsers {
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

const QUERY_ORG_USERS = gql`
  query OrgUsers($orgId: ID!, $orgRoleIds: [ID!]) {
    orgUsers(orgId: $orgId, orgRoleIds: $orgRoleIds) {
      ... on OrgUserConnection {
        nodes {
          ...AnotherUserInOrg
        }
      }
      ... on OrgUsersQueryDomainError {
        code
        message
      }
    }
  }
  ${ANOTHER_USER_IN_ORG_FRAGMENT}
`;

const QUERY_ORG_ROLES = gql`
  query Roles($orgId: ID!) {
    orgRoles(orgId: $orgId) {
      ... on OrgRoleConnection {
        nodes {
          id
          name
        }
      }
      ... on OrgRolesQueryDomainError {
        code
        message
      }
    }
  }
`;

const QUERY_ORG_GROUPS = gql`
  query UserGroups($orgId: ID!) {
    userGroups(orgId: $orgId, type: custom) {
      ... on UserGroupConnection {
        nodes {
          id
          name
        }
      }
      ... on UserGroupsQueryDomainError {
        code
        message
      }
    }
  }
`;

const QUERY_ORG_INVITES = gql`
  query UserInvites($orgId: ID!) {
    userInvites(state: active, orgId: $orgId) {
      ... on UserInviteConnection {
        nodes {
          id
          email
          invitedUser {
            name
          }
          createdAt
          createdBy {
            name
            email
            isSuperadmin
          }
        }
      }
      ... on UserInvitesQueryDomainError {
        code
        message
      }
    }
  }
`;

const QUERY_GROUP_USERS = gql`
  query GroupUsers($orgId: ID!, $userGroupId: ID) {
    orgUsers(orgId: $orgId, userGroupId: $userGroupId) {
      ... on OrgUserConnection {
        nodes {
          ...AnotherUserInOrg
        }
      }
    }
  }
  ${ANOTHER_USER_IN_ORG_FRAGMENT}
`;

const QUERY_USER_VIDEO_CLIPS = gql`
  query UserVideoClips($orgId: ID!) {
    videoClips(orgId: $orgId) {
      ... on VideoClipConnection {
        nodes {
          id
          video {
            name
            transcodedAttachmentUrl: transcodedMediaUrl(asAttachment: true, clck: false)
            transcodedByteSize
          }
        }
      }
      ... on VideoClipsQueryDomainError {
        code
        message
      }
    }
  }
`;

// MUTATIONS //////////////////////////////////////////////////////////////////

const SIGN_IN_MUTATION = gql`
  mutation SignIn($input: SessionCreateWithPasswordInput!) {
    session {
      createWithPassword(input: $input) {
        sessionTokens {
          accessToken
          refreshToken
          expiresAt
        }

        error {
          code
          message
        }
      }
    }
  }
`;

const SIGN_IN_WITH_TELEGRAM_MUTATION = gql`
  mutation SignInWithTelegram($input: SessionCreateWithTelegramInput!) {
    session {
      createWithTelegram(input: $input) {
        sessionTokens {
          accessToken
          expiresAt
          refreshToken
        }

        error {
          code
          message
        }
      }
    }
  }
`;

const LOG_OUT_MUTATION = gql`
  mutation LogOut($input: SessionRevokeInput!) {
    session {
      revoke(input: $input) {
        result

        error {
          code
          message
        }
      }
    }
  }
`;

const INVITE_USER_MUTATION = gql`
  mutation userInvite($input: UserInviteCreateInput!) {
    userInvite {
      create(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const REVOKE_INVITE_MUTATION = gql`
  mutation Revoke($input: UserInviteRevokeInput!) {
    userInvite {
      revoke(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const CHANGE_USER_ROLE_MUTATION = gql`
  mutation UpdateRole($input: OrgUserUpdateRoleInput!) {
    orgUser {
      updateRole(input: $input) {
        orgUser {
          ...AnotherUserInOrg
        }
        error {
          code
          message
        }
      }
    }
  }
  ${ANOTHER_USER_IN_ORG_FRAGMENT}
`;

const RENAME_USER_MUTATION = gql`
  mutation RenameUser($input: UserRenameInput!) {
    user {
      rename(input: $input) {
        user {
          id
          name
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($input: OrgUserDeleteInput!) {
    orgUser {
      delete(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($input: UserGroupCreateInput!) {
    userGroup {
      create(input: $input) {
        userGroup {
          id
          name
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const RENAME_GROUP_MUTATION = gql`
  mutation Update($input: UserGroupUpdateInput!) {
    userGroup {
      update(input: $input) {
        userGroup {
          id
          name
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const DELETE_GROUP_MUTATION = gql`
  mutation Delete($input: UserGroupDeleteInput!) {
    userGroup {
      delete(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const ADD_USER_TO_GROUP_MUTATION = gql`
  mutation AddUserToGroup($input: UserGroupAddUserInput!) {
    userGroup {
      addUser(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const REMOVE_USER_FROM_GROUP_MUTATION = gql`
  mutation RemoveUserFromGroup($input: UserGroupDeleteUserInput!) {
    userGroup {
      deleteUser(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`;

const QUERIES = {
  async getUser(orgId?: string) {
    const result = await client.query({
      query: QUERY_USER_INFO,
      variables: {
        orgId,
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity;
  },

  async getAvailableOrgs() {
    const result = await client.query({
      query: QUERY_AVAILABLE_ORGS,
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity.orgUsers;
  },

  async getAnotherUsersInOrg(orgId: string) {
    const result = await client.query({
      query: QUERY_ORG_USERS,
      variables: {
        orgId,
      },
    });

    if (result.data.orgUsers.__typename === 'OrgUsersQueryDomainError') {
      return Promise.reject(result.data.orgUsers);
    }

    return result.data.orgUsers.nodes;
  },

  async getOrgRoles(orgId: string) {
    const result = await client.query({
      query: QUERY_ORG_ROLES,
      variables: { orgId },
    });

    if (result.data.orgRoles.__typename === 'OrgRolesQueryDomainError') {
      return Promise.reject(result.data.orgRoles);
    }

    return result.data.orgRoles.nodes;
  },

  async getOrgGroups(orgId: string) {
    const result = await client.query({
      query: QUERY_ORG_GROUPS,
      variables: { orgId },
    });

    if (result.data.userGroups.__typename === 'UserGroupsQueryDomainError') {
      return Promise.reject(result.data.userGroups);
    }

    return result.data.userGroups.nodes;
  },

  async getOrgInvites(orgId: string) {
    const result = await client.query({
      query: QUERY_ORG_INVITES,
      variables: { orgId },
    });

    if (result.data.userInvites.__typename === 'UserInvitesQueryDomainError') {
      return Promise.reject(result.data.userInvites);
    }

    return result.data.userInvites.nodes;
  },

  async getGroupUsers(orgId: string, userGroupId: string) {
    const result = await client.query({
      query: QUERY_GROUP_USERS,
      variables: { orgId, userGroupId },
    });

    if (result.data.orgUsers.__typename === 'OrgUsersQueryDomainError') {
      return Promise.reject(result.data.orgUsers);
    }

    return result.data.orgUsers.nodes;
  },

  async getUserVideoClips(orgId: string) {
    const result = await client.query({
      query: QUERY_USER_VIDEO_CLIPS,
      variables: {
        orgId,
      },
    });

    if (result.data.videoClips.__typename === 'VideoClipsQueryDomainError') {
      return Promise.reject(result.data.videoClips);
    }

    return result.data.videoClips.nodes;
  },
};

const MUTATIONS = {
  async signIn(input: SignInInput) {
    const result = await client.mutate({
      mutation: SIGN_IN_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.session.createWithPassword.error) {
      return Promise.reject(data.session.createWithPassword.error);
    }

    return data.session.createWithPassword.sessionTokens;
  },

  async signInWithTelegram(input: SignInWithTelegramInput) {
    const result = await client.mutate({
      mutation: SIGN_IN_WITH_TELEGRAM_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.session.createWithTelegram.error) {
      return Promise.reject(data.session.createWithTelegram.error);
    }

    return data.session.createWithTelegram.sessionTokens;
  },

  async logOut() {
    const result = await client.mutate({
      mutation: LOG_OUT_MUTATION,
      variables: {
        input: {},
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    if (data.session.revoke.error) {
      return Promise.reject(data.session.revoke.error);
    }

    return data.session.revoke;
  },

  async inviteUser(input: UserInviteCreateInput) {
    const result = await client.mutate({
      mutation: INVITE_USER_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userInvite.create.error) {
      return Promise.reject(data.userInvite.create.error);
    }

    return data.userInvite.create.result;
  },

  async revokeInvite(input: { id: string }) {
    const result = await client.mutate({
      mutation: REVOKE_INVITE_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userInvite.revoke.error) {
      return Promise.reject(data.userInvite.revoke.error);
    }

    return data.userInvite.revoke;
  },

  async changeUserRole(input: OrgUserUpdateRoleInput) {
    const result = await client.mutate({
      mutation: CHANGE_USER_ROLE_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.orgUser.updateRole.error) {
      return Promise.reject(data.orgUser.updateRole.error);
    }

    return data.orgUser.updateRole.orgUser;
  },

  async renameUser(input: UserRenameInput) {
    const result = await client.mutate({
      mutation: RENAME_USER_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.user.rename.error) {
      return Promise.reject(data.user.rename.error);
    }

    return data.user.rename.user;
  },

  async deleteUser(input: { id: string }) {
    const result = await client.mutate({
      mutation: DELETE_USER_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.orgUser.delete.error) {
      return Promise.reject(data.orgUser.delete.error);
    }

    return data.orgUser.delete.result;
  },

  async createGroup(input: UserGroupCreateInput) {
    const result = await client.mutate({
      mutation: CREATE_GROUP_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userGroup.create.error) {
      return Promise.reject(data.userGroup.create.error);
    }

    return data.userGroup.create.userGroup;
  },

  async renameGroup(input: UserGroupUpdateInput) {
    const result = await client.mutate({
      mutation: RENAME_GROUP_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userGroup.update.error) {
      return Promise.reject(data.userGroup.update.error);
    }

    return data.userGroup.update.userGroup;
  },

  async deleteGroup(input: { id: string }) {
    const result = await client.mutate({
      mutation: DELETE_GROUP_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userGroup.delete.error) {
      return Promise.reject(data.userGroup.delete.error);
    }

    return data.userGroup.delete.result;
  },

  async addUserToGroup(input: UserGroupAddUserInput) {
    const result = await client.mutate({
      mutation: ADD_USER_TO_GROUP_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userGroup.addUser.error) {
      return Promise.reject(data.userGroup.addUser.error);
    }

    return data.userGroup.addUser.result;
  },

  async removeUserFromGroup(input: UserGroupDeleteUserInput) {
    const result = await client.mutate({
      mutation: REMOVE_USER_FROM_GROUP_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.userGroup.deleteUser.error) {
      return Promise.reject(data.userGroup.deleteUser.error);
    }

    return data.userGroup.deleteUser.result;
  },
};

export const userService = {
  ...QUERIES,
  ...MUTATIONS,
};

export default userService;
