export enum Paths {
  root = '/',
  track = '/track/:trackId',
  playlists = '/playlists',
  playlist = '/playlist/:playlistId',
  playlistPublic = '/playlist/public',
  playlistPublicCode = '/playlist/public/:code',
  signIn = '/user/sign-in',
  passwordRecovery = '/user/password-recovery',
  changePassword = '/user/change-password',
  register = '/user/register',
  users = '/users',
}
