import styled from '@emotion/styled/macro';

import LoadingScreen from 'components/Common/LoadingScreen';
import useUserStore from 'models/user';

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const { currentOrgUser, identified } = useUserStore();

  if (!identified) return <LoadingScreen />;

  if (!currentOrgUser)
    return (
      <NoPermission>
        Извините, у вас нет доступа к организации. Либо у вас отозвали доступ, либо ещё не выдали.
        Пожалуйста, обратитесь к администратору за подробностями.
      </NoPermission>
    );

  return children;
};

export default RequireAuth;

const NoPermission = styled.div`
  margin: 4rem auto;
  max-width: 420px;
  text-align: center;
`;
