import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import 'dayjs/locale/ru';
import { useNavigate } from 'react-router-dom';

import Notifications from 'components/Common/Notifications';
import useUserStore from 'models/user';
import Router from 'pages/Router';
import theme from 'theme/theme';
import { Paths } from 'utils/constants/routes';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated, hasAccessToken, fetchUser } = useUserStore();

  dayjs.locale('ru');

  useEffect(() => {
    if (hasAccessToken) {
      fetchUser();
    } else {
      if (location.pathname.includes('/user/')) return;
      if (location.pathname.includes('/playlist/public')) return;
      navigate(Paths.signIn);
    }
  }, [hasAccessToken]);

  useEffect(() => {
    if (authenticated) {
      navigate(Paths.root);
    }
  }, [authenticated]);

  useEffect(() => {
    // Injecting Yandex.Metrika script
    if (process.env.NODE_ENV === 'development') return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; 
      m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} 
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) 
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); 
      
      ym(95335818, "init", { 
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
      }); 
    `;

    document.head.append(script);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      <Router />
    </ThemeProvider>
  );
};

export default App;
