import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled/macro';

const LoadingScreen = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};

export default LoadingScreen;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
