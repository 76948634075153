import React, { useState } from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

interface ErrorPageProps {
  error: Error;
}

const ErrorPage = ({ error }: ErrorPageProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const toggleAccordion = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <ErrorPageStyled>
      <p>Что-то пошло не так.</p>

      <Button variant="contained" size="small" onClick={reloadPage}>
        Обновить страницу
      </Button>

      {error && (
        <Accordion>
          <AccordionTitle collapsed={collapsed} onClick={toggleAccordion}>
            Посмотреть код ошибки
          </AccordionTitle>

          {collapsed && <AccordionContent>{error.toString()}</AccordionContent>}
        </Accordion>
      )}
    </ErrorPageStyled>
  );
};

export default ErrorPage;

const ErrorPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  font-size: 22px;
  font-weight: 500;
`;

const Accordion = styled.div`
  margin-top: 20px;
  width: 400px;
  font-size: 16px;
  color: var(--text);
  background-color: var(--slate-4);
  border-radius: 8px;
  overflow: hidden;
`;

const AccordionTitle = styled.div<{ collapsed?: boolean }>`
  position: relative;
  padding: 5px 25px 5px 10px;
  width: 100%;
  background-color: var(--slate-6);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: ${({ collapsed }) => (collapsed ? '10px' : '15px')};
    height: 10px;
    width: 10px;
    border: 2px solid var(--text);
    border-top-color: transparent;
    border-right-color: transparent;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(-45deg)' : 'rotate(135deg)')};
  }
`;

const AccordionContent = styled.p`
  padding: 0 10px;
  color: var(--text-subtle);
`;
