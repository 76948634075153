import uniqBy from 'lodash/uniqBy';
import Cookies from 'universal-cookie';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import userService from 'services/user';
import {
  AnotherOrgUserType,
  OrgUserType,
  Permission,
  SessionTokenType,
  SignInInput,
  SignInWithTelegramInput,
  UserType,
  UserVideoClipType,
} from 'types/user';

const cookiesService = new Cookies();

const defaultUser: UserType = {
  user: {
    id: '',
    email: '',
    name: '',
    isSuperadmin: false,
  },
  orgUsers: [],
  currentOrgId: '',
  currentPermissions: [],
};

export type UserStore = {
  identified: boolean;
  authenticated: boolean;
  hasAccessToken: boolean;
  record: UserType | null;
  videoClips: UserVideoClipType[];

  currentOrgUser?: OrgUserType;
  currentOrgId?: string;
  currentPermissions: Permission[];
  anotherUsersInOrg: AnotherOrgUserType[];

  setUser: (record: UserType) => void;
  setCurrentOrg: (orgUser: OrgUserType) => void;
  fetchUser: () => Promise<UserType>;
  setSessionTokens: (sessionTokens: SessionTokenType) => void;
  removeSessionTokens: () => void;
  setOrgId: (orgId: string) => void;
  signIn: (input: SignInInput) => Promise<void>;
  signInWithTelegram: (input: SignInWithTelegramInput) => Promise<void>;
  logOut: () => Promise<void>;
  checkPermission: (permission: Permission) => boolean;
  getVideoClips: (orgId: string) => Promise<void>;
  fetchAnotherUsersInOrg: () => Promise<AnotherOrgUserType[]>;
  changeUserRole: (orgUserId: string, orgRoleId: string) => Promise<void>;
  renameUser: (userId: string, name: string) => Promise<void>;
  deleteUser: (orgUserId: string) => Promise<void>;
};

export const useUserStore = create(
  immer<UserStore, []>((set, get) => ({
    record: defaultUser,

    hasAccessToken: cookiesService.get('accessToken'),
    identified: false,
    authenticated: false,
    currentPermissions: [],
    anotherUsersInOrg: [],
    videoClips: [],

    setSessionTokens: (sessionTokens: SessionTokenType) => {
      const { accessToken, refreshToken, expiresAt } = sessionTokens;

      cookiesService.set('accessToken', accessToken, { path: '/', expires: new Date(expiresAt) });
      cookiesService.set('refreshToken', refreshToken, {
        path: '/',
        expires: new Date(expiresAt),
      });
      cookiesService.set('tokenExpiresAt', expiresAt, {
        path: '/',
        expires: new Date(expiresAt),
      });
    },

    removeSessionTokens: () => {
      cookiesService.remove('accessToken', { path: '/' });
      cookiesService.remove('refreshToken', { path: '/' });
      cookiesService.remove('tokenExpiresAt', { path: '/' });
    },

    setOrgId: (orgId) => {
      cookiesService.set('orgId', orgId, {
        path: '/',
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      });
      set({ currentOrgId: orgId });
    },

    setCurrentOrg: (orgUser) => {
      set({
        currentOrgUser: orgUser,
        currentOrgId: orgUser?.organization.id,
        currentPermissions: orgUser?.orgRole.orgRolePermissions.map(
          (permission) => permission.action,
        ),
        identified: true,
      });
      get().setOrgId(orgUser?.organization.id);
    },

    signIn: async (input) => {
      try {
        const sessionTokens = await userService.signIn(input);

        get().setSessionTokens(sessionTokens);
        set({ authenticated: true, hasAccessToken: true });
      } catch (exx) {
        return Promise.reject(exx);
      }
    },

    signInWithTelegram: async (input) => {
      try {
        const sessionTokens = await userService.signInWithTelegram(input);

        get().setSessionTokens(sessionTokens);
        set({ authenticated: true, hasAccessToken: true });
      } catch (exx) {
        return Promise.reject(exx);
      }
    },

    setUser: (record) => {
      const cookies = cookiesService.getAll();
      const currentOrgUser = record.orgUsers.find(
        (orgUser: OrgUserType) => orgUser.organization.id === cookies.orgId,
      );
      get().setCurrentOrg(currentOrgUser || record.orgUsers[0]);
      set({ record });
      get().getVideoClips(cookies.orgId);
    },

    logOut: async () => {
      try {
        await userService.logOut();

        get().removeSessionTokens();
        set({
          authenticated: false,
          hasAccessToken: false,
          identified: false,
          currentOrgId: '',
          record: defaultUser,
          currentPermissions: [],
        });
      } catch (exx) {
        return Promise.reject(exx);
      }
    },

    fetchUser: async () => {
      const cookies = cookiesService.getAll();
      let currentIdentity = await userService.getUser(cookies.orgId);

      if (cookies.orgId) {
        // get all available orgs without permissions info and add them into the current identity
        const availableOrgs = await userService.getAvailableOrgs();
        const normalizedOrgs = availableOrgs.map((orgUser: OrgUserType) => ({
          ...orgUser,
          orgRole: { orgRolePermissions: [] },
        }));

        currentIdentity = {
          ...currentIdentity,
          orgUsers: uniqBy([...currentIdentity.orgUsers, ...normalizedOrgs], 'organization.id'),
        };
      }

      get().setUser(currentIdentity);
      get().fetchAnotherUsersInOrg();

      return currentIdentity;
    },

    fetchAnotherUsersInOrg: async () => {
      if (get().anotherUsersInOrg.length > 0) return;

      const cookies = cookiesService.getAll();
      const anotherUsersInOrg = await userService.getAnotherUsersInOrg(cookies.orgId);

      set((state) => {
        state.anotherUsersInOrg = anotherUsersInOrg;
      });

      return anotherUsersInOrg;
    },

    changeUserRole: async (orgUserId, orgRoleId) => {
      try {
        const result = await userService.changeUserRole({ orgUserId, orgRoleId });
        set((state) => {
          state.anotherUsersInOrg = state.anotherUsersInOrg.map((user) => {
            return user.id === orgUserId ? result : user;
          });
        });
      } catch (exx) {
        console.error(exx);
        throw exx;
      }
    },

    renameUser: async (userId, name) => {
      try {
        await userService.renameUser({ userId, name });
        set((state) => {
          state.anotherUsersInOrg = state.anotherUsersInOrg.map((user) => {
            return user.user.id === userId ? { ...user, user: { ...user.user, name } } : user;
          });
        });
      } catch (exx) {
        console.error(exx);
        throw exx;
      }
    },

    deleteUser: async (id) => {
      try {
        await userService.deleteUser({ id });
        set((state) => {
          state.anotherUsersInOrg = state.anotherUsersInOrg.filter((user) => user.id !== id);
        });
      } catch (exx) {
        console.error(exx);
        throw exx;
      }
    },

    checkPermission: (permission: Permission) => {
      return get().currentPermissions.includes(permission);
    },

    getVideoClips: async (orgId: string) => {
      if (orgId === 'undefined') return;
      const videoClips = await userService.getUserVideoClips(orgId);

      set({ videoClips });
    },
  })),
);

export default useUserStore;
