import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

import { CookieNames } from 'types/user';
import { Paths } from 'utils/constants/routes';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($input: SessionRefreshInput!) {
    session {
      refresh(input: $input) {
        sessionTokens {
          accessToken
          refreshToken
          expiresAt
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const TrackToken = () => {
  const [cookies, setCookie] = useCookies();

  const [accessToken, setAccessToken] = useState(cookies.accessToken);
  const [refreshToken, setRefreshToken] = useState(cookies.refreshToken);
  const [tokenExpiresAt, setTokenExpiresAt] = useState(cookies.tokenExpiresAt);

  const [onRefreshToken] = useMutation(REFRESH_TOKEN);

  useEffect(() => {
    if (accessToken) {
      const interval = setInterval(() => {
        const now = dayjs();
        const expiresAt = dayjs(tokenExpiresAt);
        const diff = expiresAt.diff(now, 'minutes');

        if (diff <= 6) {
          onRefreshToken({
            variables: { input: { accessToken, refreshToken } },
            onError: (error) => console.log(error),
            onCompleted: (data) => {
              const { accessToken, refreshToken, expiresAt } = data.session.refresh.sessionTokens;

              setCookie(CookieNames.accessToken, accessToken, {
                path: Paths.root,
                expires: new Date(expiresAt),
              });
              setCookie(CookieNames.refreshToken, refreshToken, {
                path: Paths.root,
                expires: new Date(expiresAt),
              });
              setCookie(CookieNames.tokenExpiresAt, expiresAt, {
                path: Paths.root,
                expires: new Date(expiresAt),
              });

              setAccessToken(accessToken);
              setRefreshToken(refreshToken);
              setTokenExpiresAt(expiresAt);
            },
          });
        }
      }, 300000); // 5 minutes

      return () => clearInterval(interval);
    }
  }, [accessToken]);

  return null;
};

export default TrackToken;
