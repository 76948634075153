import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: (operation) => `${'https://api.rbdata.ru/graphql'}?${operation.operationName}`,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = cookies.get('accessToken');

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
export { useQuery } from '@apollo/client';
