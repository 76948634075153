import React, { memo, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack';
import styled from '@emotion/styled';

import useNotificationsStore from 'models/notifications';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon sx={{ fontSize: 14 }} />
    </IconButton>
  );
};

const Container = () => {
  const { enqueueSnackbar } = useSnackbar();
  const notificationsStore = useNotificationsStore();
  const { message } = notificationsStore;

  useEffect(() => {
    if (message) {
      if (message.error && message.error.message) {
        enqueueSnackbar(message.error.message, { variant: message.type });
      }

      enqueueSnackbar(message.title, {
        variant: message.type,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  }, [message]);

  return <React.Fragment />;
};

const Notifications = () => {
  return (
    <SnackbarProvider
      Components={{
        default: NotificationStyled,
        success: NotificationStyled,
        error: NotificationStyled,
        info: NotificationStyled,
        warning: NotificationStyled,
      }}
      maxSnack={6}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      <Container />
    </SnackbarProvider>
  );
};

export default memo(Notifications);

const NotificationStyled = styled(MaterialDesignContent)`
  &.notistack-MuiContent-default {
    background-color: var(--slate-12);
  }

  &.notistack-MuiContent-success {
    background-color: var(--success);
  }

  &.notistack-MuiContent-error {
    background-color: var(--error);
  }

  &.notistack-MuiContent-info {
    background-color: var(--cyan-9);
  }

  &.notistack-MuiContent-warning {
    background-color: var(--warning);
  }
`;
